

.pload-history-title {
    margin-top: 56px;
    padding-top: 12px;
    padding-bottom: 24px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, .04);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1218px;
    max-height: max-content;
}

.ss-loader {
  position: absolute;
  /* top: 25%; */
  /* left: 46%; */
  width: 100px;
  height: 100px;
  border: 5px solid #FFF;
  border-bottom-color: #d1836b;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.ss-modal {
    
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.chat_container .chat_container_main .chat_label {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.chat_container .chat_container_main .chat_dragger {
  display: flex;
  margin-bottom: 32px;
  flex-direction: column;
}

.chat_container .chat_container_main .chat_dragger.chat_dragger_hide_upload .ant-upload-drag {
  display: none;
}

.chat_container .chat_container_main .chat_dragger .ant-upload-list {
  display: flex;
}

.chat_container .chat_container_main .chat_dragger .ant-upload-drag {
  border: 1px dashed #000;
}

.chat_container .chat_container_main .chat_dragger .chat_dragger_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px 0;
}

.chat_container .chat_container_main .chat_dragger .chat_dragger_container .chat_dragger_text {
  margin-top: 16px;
  font-family: "Inter";
}

.chat_container .chat_container_main .chat_description {
  min-height: 130px;
  border: 1px dashed #000;;
  border-radius: 12px;
  width: 100%;
  outline: none;
  padding: 16px 24px;
  margin-bottom: 20px;
}

.chat_container .chat_container_main .btn_chat_generate {
  background-color: #0066FF;
  color: #fff;
  border-radius: 12px;
  width: 320px;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  gap: 5px;
  justify-self: center;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .chat_container .chat_container_main .chat_dragger .chat_dragger_container {
    padding: 20px;
  }

  .chat_container .chat_container_main .btn_chat_generate {
    width: 100%;
  }
}