.Header img {
    width: 100px;
    height: auto;
    margin: 20px;
}
.Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 72px;
    padding-right: 72px;
    align-items: center;
    background-color: #ffffff;
    color: white;
    /* padding: 10px; */
}