.how_it_work_container {
    width: 420px;
    background-color: #fff;
    border-radius: 12px;
    padding: 24px 30px;
}

.how_it_work_container .how_it_work_title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 18px;
}

.how_it_work_container .how_it_work_block {
    border: 1px solid #C9C9C9;
    border-radius: 12px;
    padding: 20px 10px;
    display: flex;
    margin-bottom: 18px;
}

.how_it_work_container .how_it_work_block .how_it_work_block_info {
    margin-left: 5px;
}

.how_it_work_container .how_it_work_block .how_it_work_block_info .how_it_work_block_title {
    margin-right: 5px;
}

.how_it_work_container .how_it_work_block .how_it_work_block_info .how_it_work_block_title,
.how_it_work_container .how_it_work_block .how_it_work_no {
    font-size: 18px;
    font-weight: 700;
}

@media (max-width: 768px) {
    .how_it_work_container {
        width: 100%;
    }
}