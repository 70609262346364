.mainHomeContainer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
    font-family: "monsterrat bold", Sans-serif;
    background-color: #f3f3f9;
    height: 70vh;
}

.homeTitleContainer{
    font-size: 30px;
    font-weight: bolder;
    font-family: "Noto sans","Poppins",arial,microsoft yahei,pingfangsc,helvetica,sans-serif!important;
    color: #000;
    margin: 0;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: top;
    align-items: top;
    flex-direction: column;
    /* margin-top: 50px; */
    /* margin-bottom: 5px; */
    font-family: "monsterrat bold", Sans-serif;
}

.benefits-list {
    /* display: flex;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

.benefits-list .block {
    /* flex: 0 0 30%; */
    /* max-width: 30%; */
    margin-bottom: 20px;
  }
  
.benefits-list li {
/* flex: 1; */
margin: 0;
padding: 0;
width: 210px;
height: 150px;
border-radius: 30px;
background-color:rgb(217, 236, 162, 0.5);
flex-direction: column;
display: flex;
justify-content: center;
align-items: center;
font-size: 12px;
}

.benefits-list li text{
    flex: 1;
    margin: 0;
    padding: 0;
    width: 200px;
    height: 150px;
    border-radius: 30px;
    background-color:rgb(217, 236, 162);
    }

.benefits-list li:hover{
    background-color: #7492f3; /* Change this to the color you want */
    }

    .home-block {
        color: rgb(38, 66, 109);
        font-family: "monsterrat bold", Sans-serif;
    }