.chat-history {
    width: 320px;
    background-color: #fff;
    border-radius: 12px;
    padding: 24px 30px;
  }
  
  .chat-history h2 {
    /* font-size: 1.5em;
    margin-bottom: 10px; */
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 18px;
  }
  
  .chat-history ul {
    list-style-type: none;
    padding: 0;
    max-height: 500px; 
    overflow-y: auto; 
  }
  
  .chat-history li {
    /* border: 1px solid #ccc; */
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    transition: background-color 0.3s ease; 
  }
  
  .chat-history li:hover {
    background-color: #f0f0f0; 
    cursor: pointer;
  }
  
  .chat-session p {
    margin: 5px 0;
  }