@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_28pt-Regular.ttf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chat_container {
  display: flex;
  gap: 36px;
}

.chat_container .chat_container_main {
  padding: 48px 90px;
  background-color: #fff;
  border-radius: 12px;
  width: 100%;
  width: calc(100% - 300px);
}

@media (max-width: 1280px) {
  .chat_container .chat_container_main {
    padding: 30px;
    width: calc(100% - 200px);
  }
}

@media (max-width: 768px) {
  .chat_container {
    flex-direction: column;
  }

  .chat_container .chat_container_main {
    padding: 20px;
    width: 100%;
  }
}