.chat_layout {
    padding: 20px 70px;
    font-family: 'Inter';
    background-color: #F3F3F8;
}

.breadcrumb {
    display: flex;
}

.breadcrumb .breadcrumb_item {
    margin: 0 4px;
    font-size: 16px;
}

.breadcrumb .breadcrumb_parent {
    color: #000;
    opacity: 0.45;
}

.page_title {
    margin-top: 15px;
    font-weight: 600;
    font-size: 32px;
}

.page_content {
    margin-top: 20px;
}

.page_desc {
    font-size: 18px;
}

.other_tool {
    margin-top: 50px;
    margin-bottom: 50px;
}

.other_tool .other_tool_title {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 28px;
}

.other_tool .other_tool_row {
    display: flex;
    gap: 36px;
}

.other_tool .other_tool_row_second {
    margin-top: 28px;
    justify-content: center;
}

.other_tool .other_tool_row .other_tool_item {
    background-color: #fff;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 24px;
    flex: 0.33333;
    cursor: pointer;
}

.other_tool .other_tool_row .other_tool_item img {
    align-self: start;
    margin-top: 8px;
}

.other_tool .other_tool_row .other_tool_item .other_tool_item_title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}

.other_tool .other_tool_row .other_tool_item .other_tool_item_text {
    font-size: 18px;
}

@media (max-width: 768px) {
    .chat_layout {
        padding: 20px;
        font-family: 'Inter';
        background-color: #F3F3F8;
    }

    .other_tool .other_tool_row {
        flex-direction: column;
    }
}