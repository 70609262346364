.MainUI {
    display: flex;
    flex-direction: row;
    height: 88vh;
}

.document {
    flex: 1.1;
    background-color: rgb(247, 245, 243);
    height: auto;
    width: 500px;
    /* height: 900px; */
    /* max-width: 100%; */
    /* max-height: 100%; */
}
.document .title {
    font-size: 1.5em;
    font-weight: bold;
    margin: 10px;
    /* border: 1px solid #000;
    height: 50px; */
}

.pdfviewer{
    width: 100%;
    height: calc(100%);
    border: none;
}

/* .button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
} */

.chat {
    position: relative;
    flex: 1;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    height: auto;
    background-color: aliceblue;
    width: 100px;
}
.ant-spin-nested-loading{
    height: 100%;

}

.ant-spin-container {
    height: 100%;
}

.button:hover {
    background-color: #0056b3;
}

/* .className {
    display: flex;
    flex-direction: column;
    height: 100%;
} */

.chat {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.title2 {
    /* border: 1px solid #000; */
    /* height: 50px; */
    width: 100%;
    /* font-size: 1.5em; */
    font-weight: bold;
    color: rgb(255, 255, 255);
    background-color: rgb(42 22 92);
    padding: 5px;
}

.title-confluence {
    /* border: 1px solid #000; */
    height: 32px;
    width: 100%;
    /* font-size: 1.5em; */
    font-weight: bold;
    color: rgb(255, 255, 255);
    background-color: rgb(6, 70, 187);
    padding: 5px;
    text-align: center;
    font-size: 130%;
    display: flex;
    align-items: center;
    justify-content: center;    
}

.conversationRow {
    flex: 1;
    overflow: auto;
    width: 100%;
}

.conversation {
    /* display: flex; */
    /* flex-direction: column; */
    padding: 20px;
    width: 100%;
}

.inputMessage {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #000;
    border-radius: 5px;
}

.button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #0056b3;
}
.message {
    /* display: flex; */
    /* justify-content: flex-end; */
    width: fit-content;
    max-width: 70%;
    border: 1px solid gray;
    padding: 0px 20px;
    border-radius: 16px;
    overflow: auto;
    /* background-color: rgb(231, 225, 225); */
}

.user {
    /* text-align: right; */
    /* background-color: #acb1b8; */
    margin-bottom: 5px;
    /* width: auto; */
    display: flex;
    justify-content: flex-end;
}

.bot {
    /* text-align: left;
    background-color: #f7c6c6; */
    margin-bottom: 5px;
    /* width: auto; */
}

.bot .message {
    background-color: #efefef;
}

.user .message {
    background-color: #0798ed;
    color: white;
}

.input-container {
    width: 100%;
    display: flex;
    padding: 10px;
    background-color: #f2f2f2;
    border-top: 1px solid #ddd;
  }
  
  .message-input {
    flex: 1;
    padding: 8px;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .send-button {
    padding: 8px 20px;
    border: none;
    background-color: #0049e6;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }

  .new-chat-button {
    /* padding: 8px 20px; */
    /* border: none; */
    background-color: #0049e6;
    color: #fff;
    /* border-radius: 4px; */
    /* cursor: pointer; */
  }

.message-input:focus {
    outline-width: 0;
    border:1px solid rgb(116, 225, 245);
}

/* .modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
} */

.modal {
    
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #3283fd;
    padding: 20px;
    border: 1px solid #888;
    width: 10%;
    text-align: center;
    animation: spin 2s linear infinite;
    border-radius: 50%;
        /* Makes the element circular */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.loader {
    position: absolute;
    top: 35%;
    left: 46%;
    width: 70px;
    height: 70px;
    border: 5px solid #FFF;
    border-bottom-color: #d1836b;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.markdown-table table {
    border-collapse: collapse;
}

.markdown-table th,
.markdown-table td {
    border: 1px solid black;
    padding: 10px;
}

/* .break-word td {
    word-break: break-all;
  } */
.table-cell {
    word-break: break-all;
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis;
  }

.markdown-table-2 {
    border-collapse: collapse;
}

.right-align {
justify-content: flex-end;
align-items: center;
float: right;
background-color: #e2dfb7;
color: #000000;
display: flex;
height: 100%;
padding-inline-end: 10px;
width: 20%;
border-radius: 8px;
overflow: hidden;
white-space: nowrap; 
text-overflow: ellipsis;
direction: ltr;
text-align: left;
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .message-input-wrapper {
    position: relative;
    flex: 1;
  }
  
  .message-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .message-input:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  
  .loading-dots {
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    gap: 5px;
    font-size: 20px;
    color: #175e09;
  }
  
  .loading-dots span {
    animation: blink 1.5s infinite;
  }
  
  .loading-dots span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .loading-dots span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes blink {
    0%, 80%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
  