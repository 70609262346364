.db-body {
  font-family: "Noto sans","Poppins",arial,microsoft yahei,pingfangsc,helvetica,sans-serif!important;
  color: #000;
  margin: 0;
  padding: 0;
  background-color: #f3f3f9;
  width: 100%;
  height: 70vh;
}

.db-instroduce {
  font-size: 30px;
  font-family: "Noto sans","Poppins",arial,microsoft yahei,pingfangsc,helvetica,sans-serif!important;
  color: #000;
  margin: 0;
  padding-top: 40px;
  padding-bottom: 20px;
  text-align: center;
}


.db-benefits-list {
  /* display: flex; */
  justify-content: space-between;
  gap: 40px;
}

.db-benefits-list li {
/* flex: 1; */
margin: 0;
padding: 0;
width: 800px;
height: 500px;
border-radius: 40px;
background-color:rgba(255, 255, 255, 0.5);
flex-direction: column;
display: flex;
justify-content: center;
align-items: center;
}

.db-benefits-list li text{
  flex: 1;
  margin: 0;
  padding: 0;
  width: 100px;
  height: 200px;
  border-radius: 40px;
  background-color:rgb(255, 255, 255);
  }


  /* .home-block {
      color: rgb(38, 66, 109);
      font-family: "monsterrat bold", Sans-serif;
  } */


.db-working {
  font-size: 40px;
  font-family: "Noto sans","Poppins",arial,microsoft yahei,pingfangsc,helvetica,sans-serif!important;
  color: #000;
  /* margin: 0; */
  /* padding: 50px; */
  /* text-align: center; */
  /* display: "flex"; */
  /* justifyContent: "center"; */
  background-color: #ffffff;
  width: 600px;
  height: 300px;
  border-radius: 40px;
}

.chat_container .chat_container_main .chat_label {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.chat_container .chat_container_main .chat_dragger {
  display: flex;
  margin-bottom: 32px;
  flex-direction: column;
}

.chat_container .chat_container_main .chat_dragger.chat_dragger_hide_upload .ant-upload-drag {
  display: none;
}

.chat_container .chat_container_main .chat_dragger .ant-upload-list {
  display: flex;
}

.chat_container .chat_container_main .chat_dragger .ant-upload-drag {
  border: 1px dashed #000;
}

.chat_container .chat_container_main .chat_dragger .chat_dragger_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px 0;
}

.chat_container .chat_container_main .chat_dragger .chat_dragger_container .chat_dragger_text {
  margin-top: 16px;
  font-family: "Inter";
}

.chat_container .chat_container_main .chat_description {
  min-height: 130px;
  border: 1px dashed #000;;
  border-radius: 12px;
  width: 100%;
  outline: none;
  padding: 16px 24px;
  margin-bottom: 20px;
}

.chat_container .chat_container_main .btn_chat_generate {
  background-color: #0066FF;
  color: #fff;
  border-radius: 12px;
  width: 320px;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  gap: 5px;
  justify-self: center;
  margin: 0 auto;
}

.chat_container .chat_container_main .ant-tabs-tab-btn {
  font-size: 16px;
  font-family: "Inter";
}

.chat_container .chat_container_main .db_chat_label {
  font-size: 18px;
  font-family: "Inter";
  font-weight: 700;
}

.chat_container .chat_container_main .form_db_chat .ant-select-selection-item {
  font-family: "Inter";
  font-size: 16px;
}

.chat_container .chat_container_main .form_db_chat .db_chat_input {
  padding: 8px 10px;
  font-family: "Inter";
  font-size: 16px;
  border: 1px dashed #000;
  margin-top: 10px;
}

.chat_container .chat_container_main .form_db_chat .ant-select {
  height: 43px;
  margin-top: 10px;
}

.chat_container .chat_container_main .form_db_chat .ant-col-form {
  padding: 0 5px;
}

.chat_container .chat_container_main .form_db_chat .ant-select .ant-select-selector {
  border: 1px dashed #000;
}

.ant-select-item-option-content {
  padding: 5px;
  font-family: "Inter";
  font-size: 16px;
}

.chat_container .chat_container_main .form_db_chat .ant-col-form  .ant-select-selection-placeholder {
  font-family: "Inter";
  font-size: 16px;
}

@media (max-width: 768px) {
  .chat_container .chat_container_main .chat_dragger .chat_dragger_container {
    padding: 20px;
  }

  .chat_container .chat_container_main .btn_chat_generate {
    width: 100%;
  }
}