.cf-body {
  font-family: "Noto sans", "Poppins", arial, microsoft yahei, pingfangsc, helvetica, sans-serif !important;
  color: #000;
  margin: 0;
  padding: 0;
  background-color: #f3f3f9;
  width: 100%;
  height: 70vh;
}

.cf-instroduce {
  font-size: 30px;
  font-family: "Noto sans", "Poppins", arial, microsoft yahei, pingfangsc, helvetica, sans-serif !important;
  color: #000;
  margin: 0;
  padding-top: 40px;
  padding-bottom: 20px;
  text-align: center;
}


.cf-benefits-list {
  /* display: flex; */
  justify-content: space-between;
  gap: 40px;
}

.cf-benefits-list li {
  /* flex: 1; */
  margin: 0;
  padding: 0;
  width: 800px;
  height: 500px;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cf-benefits-list li text {
  flex: 1;
  margin: 0;
  padding: 0;
  width: 100px;
  height: 200px;
  border-radius: 40px;
  background-color: rgb(255, 255, 255);
}


/* .home-block {
      color: rgb(38, 66, 109);
      font-family: "monsterrat bold", Sans-serif;
  } */


.cf-working {
  font-size: 40px;
  font-family: "Noto sans", "Poppins", arial, microsoft yahei, pingfangsc, helvetica, sans-serif !important;
  color: #000;
  /* margin: 0; */
  /* padding: 50px; */
  /* text-align: center; */
  /* display: "flex"; */
  /* justifyContent: "center"; */
  background-color: #ffffff;
  width: 600px;
  height: 300px;
  border-radius: 40px;
}

.icon-image {
  /* Adjust the position of the image here */
  vertical-align: middle;
  /* Align the image vertically in the middle */
}

.wiki_chat_container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.wiki_chat_container form {
  width: 80%;
}

.wiki_chat_container .wiki_chat_label {
  font-size: 18px;
  font-family: "Inter";
  font-weight: 700;
}
.wiki_chat_container .wiki_chat_input {
  padding: 12px;
  font-family: "Inter";
  font-size: 16px;
  border: 1px dashed #000;
  margin-top: 10px;
}

.wiki_chat_container .wiki_chat_input.ant-input-status-error {
  border-style: dashed;
}