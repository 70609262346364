.ss-loader {
  position: absolute;
  /* top: 25%; */
  /* left: 46%; */
  width: 100px;
  height: 100px;
  border: 5px solid #FFF;
  border-bottom-color: #d1836b;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.ss-modal {
    
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.web_chat_container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.web_chat_container .web_chat_label {
  font-size: 18px;
  font-weight: 700;
}

.web_chat_container .web_chat_input {
  border-radius: 16px;
  border: 1px dashed #000;
  padding: 20px 24px;
  width: 100%;
  outline: none;
  margin: 20px 0;
}

.web_chat_container .web_chat_btn {
  color: #fff;
  background-color: #0066FF;
  width: 320px;
  padding: 12px 0;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .web_chat_container .web_chat_btn {
    width: 100%;
  }

  .web_chat_container .web_chat_input {
    padding: 15px;
  }
}